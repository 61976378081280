import { useReducer } from 'react'

import type { CRMObject } from 'types/graphql'

export type SuggestedContextState = {
  parentObject: CRMObject
  workspaceId: string
  referencedObjects: CRMObject[]
  dismissedObjects: CRMObject[]
  suggestedObjects: CRMObject[]
}

const cleanObjects = (objects: CRMObject[]) => {
  return objects
    .map((o) => ({
      objectId: o.objectId,
      objectType: o.objectType,
      workspaceId: o.workspaceId,
    }))
    .filter((o) => o.objectId && o.objectType)
}

export type SuggestedContextAction =
  | {
      type: 'CONTEXT_OBJECTS_ADDED'
      payload: CRMObject[]
    }
  | {
      type: 'SUGGESTED_CONTEXT_RETURNED'
      payload: {
        objectsToAdd: CRMObject[]
        objectsToSuggest: CRMObject[]
      }
    }
  | {
      type: 'CONTEXT_OBJECT_DISMISSED'
      payload: CRMObject
    }

const suggestedContextReducer = (
  state: SuggestedContextState,
  action: SuggestedContextAction
): SuggestedContextState => {
  switch (action.type) {
    case 'CONTEXT_OBJECTS_ADDED': {
      const newReferencedObjects = [
        ...state.referencedObjects,
        ...action.payload.filter(
          (newObj) =>
            !state.referencedObjects.some(
              (existingObj) => existingObj.objectId === newObj.objectId
            )
        ),
      ]

      return {
        ...state,
        parentObject: newReferencedObjects?.[0],
        suggestedObjects: state.suggestedObjects.filter(
          (existingObject) =>
            !action.payload.some(
              (newObject) => newObject.objectId === existingObject.objectId
            )
        ),
        referencedObjects: newReferencedObjects,
      }
    }
    case 'CONTEXT_OBJECT_DISMISSED': {
      const newReferencedObjects = state.referencedObjects.filter(
        (o) => o.objectId !== action.payload.objectId
      )

      const newDismissedObjects = [...state.dismissedObjects, action.payload]

      return {
        ...state,
        parentObject: newReferencedObjects?.[0],
        referencedObjects: newReferencedObjects,
        suggestedObjects: state.suggestedObjects.filter(
          (o) => o.objectId !== action.payload.objectId
        ),
        dismissedObjects: newDismissedObjects,
      }
    }
    case 'SUGGESTED_CONTEXT_RETURNED': {
      const combinedObjects = [
        ...state.referencedObjects,
        ...action.payload.objectsToAdd,
      ]

      const uniqueReferencedObjectsMap = new Map<string, CRMObject>()
      combinedObjects.forEach((obj) => {
        if (obj.objectId && !uniqueReferencedObjectsMap.has(obj.objectId)) {
          uniqueReferencedObjectsMap.set(obj.objectId, obj)
        }
      })

      const uniqueReferencedObjects = Array.from(
        uniqueReferencedObjectsMap.values()
      )

      return {
        ...state,
        suggestedObjects: cleanObjects(action.payload.objectsToSuggest).filter(
          (suggestedObj) =>
            !uniqueReferencedObjects.some(
              (refObj) => refObj.objectId === suggestedObj.objectId
            )
        ),
        parentObject: action.payload.objectsToAdd?.[0] ?? state.parentObject,
        referencedObjects: cleanObjects(uniqueReferencedObjects),
      }
    }
  }
  return state
}

const initializeState = ({
  parentObject,
  initialContextObjects,
  initialDismissedObjects,
  workspaceId,
}: {
  parentObject: Partial<CRMObject>
  initialContextObjects: Partial<CRMObject>[]
  initialDismissedObjects: Partial<CRMObject>[]
  workspaceId: string
}) => {
  return {
    parentObject: {
      ...parentObject,
      workspaceId,
    },
    referencedObjects: initialContextObjects.map((o) => ({
      ...o,
      workspaceId,
    })),
    dismissedObjects: initialDismissedObjects.map((o) => ({
      ...o,
      workspaceId,
    })),
    suggestedObjects: [],
  } as SuggestedContextState
}

export const useSuggestedContextReducer = ({
  parentObject,
  initialContextObjects,
  initialDismissedObjects,
  workspaceId,
}: {
  parentObject: Partial<CRMObject>
  initialContextObjects: Partial<CRMObject>[]
  initialDismissedObjects: Partial<CRMObject>[]
  workspaceId: string
}): { state: SuggestedContextState; dispatch } => {
  const [state, dispatch] = useReducer(
    suggestedContextReducer,
    {
      parentObject,
      initialContextObjects,
      initialDismissedObjects,
      workspaceId,
    },
    initializeState
  )

  return { state, dispatch }
}
