import {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  memo,
} from 'react'

import { Avatar } from '@mui/material'
import { IconCircleFilled } from '@tabler/icons-react'
import type { CRMObject, Page } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { GET_CHIP_METADATA } from 'src/components/Objects/queries'
import { getDayObjectSearchEntry } from 'src/components/Objects/searchIndex'
import SlackLogo from 'src/components/Slack/SlackLogo/SlackLogo'
import { getTemplateIcon } from 'src/components/Templates/TemplateIcon/utils'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { getBestLabel } from 'src/lib/objectLabels'
import {
  NativeObjectTypes,
  ObjectTypeMetadata,
  type NativeObjectType,
} from 'src/lib/objects'

import ContactAvatar from '../../ContactAvatar/ContactAvatar'
import DomainAvatar from '../../DomainAvatar/DomainAvatar'
import BaseChip from '../BaseChip'

const avatarSize = 16

// --- Feature Flag ---
const USE_SEARCH_INDEX_FIRST = true // Set to true to enable fetching from searchIndex first
// --- End Feature Flag ---

interface ObjectChipProps {
  workspaceId: string
  crmObject: Partial<CRMObject>
  fullWidth?: boolean
  showSidebar?: boolean
  isSuggested?: boolean
  onAdd?: (object: Partial<CRMObject>) => void
  onRemove?: (object: Partial<CRMObject>) => void
}

const ObjectChipComponent = ({
  workspaceId,
  crmObject,
  fullWidth = false,
  showSidebar = false,
  isSuggested = false,
  onAdd = null,
  onRemove,
}: ObjectChipProps) => {
  const { setSidebarObject } = useContext(DayContext)
  const [dayObject, setDayObject] = useState<DayObject | null>(null)
  const [localMetadata, setLocalMetadata] = useState<{
    label: string | null
    photoUrl: string | null
  }>({ label: null, photoUrl: null })

  const notFoundInIndexedDB = useRef(false)

  // Log localMetadata on each render
  logger.dev('ObjectChip Render: localMetadata is currently:', {
    localMetadata,
    objectId: crmObject.objectId,
  })

  const {
    objectType,
    objectId,
    properties: objectProperties,
  } = useMemo(() => crmObject || {}, [crmObject])

  // Effect to fetch initial metadata from searchIndex if flag is enabled
  useEffect(() => {
    // Log inputs at the start of the effect
    logger.dev('ObjectChip useEffect: Running with:', {
      workspaceId,
      objectType,
      objectId,
    })

    // Reset state and flag on ID change
    logger.dev(
      'ObjectChip useEffect: Resetting localMetadata and notFound flag',
      { objectId }
    )
    setLocalMetadata({ label: null, photoUrl: null })
    notFoundInIndexedDB.current = false

    if (!workspaceId || !objectType || !objectId) {
      logger.dev('ObjectChip: Missing required IDs, skipping initial fetch.')
      return // Exit if essential IDs are missing
    }

    if (USE_SEARCH_INDEX_FIRST) {
      logger.dev('ObjectChip: Attempting fetch from searchIndex', {
        objectId,
        objectType,
      })
      const fetchFromIndex = async () => {
        try {
          const searchEntry = await getDayObjectSearchEntry({
            workspaceId,
            objectType: objectType as NativeObjectType,
            objectId,
          })

          // Log the raw searchEntry before conditional logic
          logger.dev('ObjectChip useEffect: Raw searchEntry received:', {
            searchEntry,
            objectId,
          })

          if (searchEntry) {
            logger.dev('ObjectChip: Found in searchIndex', {
              objectId,
              objectType,
              label: searchEntry.label,
              photoUrl: searchEntry.photoUrl,
            })
            // Prepare the new metadata object
            const newMetadata = {
              label: searchEntry.label || null, // Primarily use the pre-computed label
              photoUrl: searchEntry.photoUrl,
            }
            setDayObject(searchEntry?.object)
            // Log the value being set
            logger.dev('ObjectChip useEffect: Calling setLocalMetadata with:', {
              newMetadata,
              objectId,
            })
            // Update local state immediately with data from index
            setLocalMetadata(newMetadata)
            // Keep notFoundInIndexedDB.current as false, so GraphQL query might be skipped
            // Note: The GraphQL query might still run if its other skip conditions aren't met,
            // but it won't be triggered *because* of IndexedDB lookup failure.
          } else {
            logger.dev(
              'ObjectChip: Not found in searchIndex, will attempt server fetch',
              { objectId, objectType }
            )
            notFoundInIndexedDB.current = true // Allow GraphQL query to run
          }
        } catch (error) {
          logger.warn('ObjectChip: Error fetching from searchIndex', {
            error,
            objectId,
            objectType,
          })
          notFoundInIndexedDB.current = true // Allow GraphQL query to run on error
        }
      }
      fetchFromIndex()
    } else {
      // If flag is off, immediately mark as not found locally to trigger GraphQL
      logger.dev('ObjectChip: Skipping searchIndex fetch due to flag.', {
        objectId,
        objectType,
      })
      notFoundInIndexedDB.current = true
    }
    // Dependencies ensure this runs when the object identity changes or the flag (if dynamic) changes
  }, [workspaceId, objectType, objectId])

  const { data } = useQuery(GET_CHIP_METADATA, {
    variables: {
      objectId,
      objectType,
      workspaceId,
    },
    skip:
      !objectId ||
      !objectType ||
      !workspaceId ||
      [NativeObjectTypes.MeetingRecording].includes(objectType as any) ||
      !notFoundInIndexedDB.current,
  })

  const chipMetadata = data?.chipMetadata

  const handleSetSidebarObject = useCallback(() => {
    if (showSidebar) {
      setSidebarObject({
        objectType,
        objectId,
        properties: objectProperties,
      })
    }
  }, [showSidebar, objectType, objectId, objectProperties, setSidebarObject])

  const bestLabel = useMemo(() => {
    const bestLabel = getBestLabel({
      localMetadata,
      passedProperties: objectProperties as Record<string, any>,
      serverMetadata: chipMetadata,
      objectType: objectType as NativeObjectType,
      objectId,
    })
    return bestLabel
  }, [objectProperties, localMetadata, chipMetadata, objectType, objectId])

  const avatar = useMemo(() => {
    if (typeof objectType !== 'string' || typeof objectId !== 'string') {
      logger.warn('ObjectChip: Invalid objectType or objectId', {
        objectType,
        objectId,
      })
      return null
    }

    if (objectType === NativeObjectTypes.Organization) {
      return (
        <DomainAvatar
          domain={objectId}
          size={avatarSize}
        />
      )
    } else if (objectType === NativeObjectTypes.Person) {
      logger.dev('ObjectChip: Rendering ContactAvatar', {
        objectId,
        objectType,
        localMetadata,
        objectIdType: typeof objectId,
        objectTypeType: typeof objectType,
      })
      return (
        <ContactAvatar
          email={objectId}
          size={avatarSize}
          passedPhotoUrl={localMetadata.photoUrl}
          dayObject={dayObject}
        />
      )
    } else if (objectType === NativeObjectTypes.SlackChannel) {
      return <SlackLogo size={avatarSize} />
    } else if (
      objectType === NativeObjectTypes.Page &&
      (objectProperties as Page)?.templateType
    ) {
      return getTemplateIcon({
        templateType: (objectProperties as Page)?.templateType,
        variant: 'chip',
        size: avatarSize,
      })
    } else if (
      objectType === NativeObjectTypes.Page &&
      (objectProperties as Page)?.sourceTemplate?.templateType
    ) {
      return getTemplateIcon({
        templateType: (objectProperties as Page)?.sourceTemplate?.templateType,
        variant: 'chip',
        size: avatarSize,
      })
    } else {
      const objectIcon =
        ObjectTypeMetadata[objectType]?.icon || IconCircleFilled
      return (
        <Avatar
          src={null}
          sx={{
            background: (theme) => theme.palette.background.default,
          }}
        >
          {React.createElement(objectIcon, {
            size: '12px',
            stroke: 2.45,
            style: {
              marginTop: '-1px',
            },
          })}
        </Avatar>
      )
    }
  }, [objectType, objectId, objectProperties, localMetadata])

  return (
    <BaseChip
      label={bestLabel || objectId}
      avatar={avatar}
      icon={null}
      onRemove={
        isSuggested
          ? () => onAdd(crmObject)
          : onRemove
            ? () => onRemove(crmObject)
            : null
      }
      fullWidth={fullWidth}
      onClick={
        showSidebar
          ? (e) => {
              e.stopPropagation()
              handleSetSidebarObject()
            }
          : null
      }
      isSuggested={isSuggested}
    />
  )
}

const ObjectChip = memo(ObjectChipComponent, (prevProps, nextProps) => {
  return (
    prevProps.crmObject.objectId === nextProps.crmObject.objectId &&
    prevProps.crmObject.objectType === nextProps.crmObject.objectType &&
    prevProps.crmObject.workspaceId === nextProps.crmObject.workspaceId
  )
})

export default ObjectChip
