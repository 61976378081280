import { useMemo, useState, useEffect, memo } from 'react'

import { Avatar, Box, Typography } from '@mui/material'
import { IconCircleFilled } from '@tabler/icons-react'
import type { Page } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import ContactAvatar from 'src/components/ContactAvatar/ContactAvatar'
import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import {
  useObjectCache,
  getCacheKey,
} from 'src/components/ObjectCacheProvider/ObjectCacheProvider'
import { getDayObjectSearchEntry } from 'src/components/Objects/searchIndex'
import { logger } from 'src/lib/logger'
import {
  type ObjectMetadata,
  getFirstDefinedValue,
  LABEL_PROPERTIES,
  AVATAR_PROPERTIES,
  DESCRIPTION_PROPERTIES,
  getBestLabel,
} from 'src/lib/objectLabels'
import {
  NativeObjectTypes,
  ObjectTypeMetadata,
  type NativeObjectType,
} from 'src/lib/objects'
import { updateObjectInSearchIndex } from 'src/lib/searchService'

import TemplateIcon from '../Templates/TemplateIcon/TemplateIcon'

const GET_TILE_METADATA = gql`
  query TileMetadata(
    $objectId: String!
    $objectType: String!
    $workspaceId: String!
  ) {
    chipMetadata(
      objectId: $objectId
      objectType: $objectType
      workspaceId: $workspaceId
    ) {
      id
      label
      icon
      domain
      avatarUrl
      description
    }
  }
`

interface ObjectTileProps {
  objectType: string
  objectId: string
  workspaceId: string
  properties?: Record<string, any>
  size?: number
  onClick?: () => void
}

// --- Feature Flag ---
const USE_SEARCH_INDEX_FIRST = true // Set to true to enable fetching from searchIndex first
// --- End Feature Flag ---

const BaseObjectTile = memo(
  ({
    objectType,
    objectId,
    workspaceId,
    properties,
    size = 30,
    onClick,
  }: ObjectTileProps) => {
    const [localMetadata, setLocalMetadata] = useState<ObjectMetadata | null>(
      null
    )
    const [serverMetadata, setServerMetadata] = useState<ObjectMetadata | null>(
      null
    )
    const [passedProperties, setPassedProperties] =
      useState<ObjectMetadata | null>(null)
    const [shouldQueryServer, setShouldQueryServer] = useState(false)

    // Effect to format passed properties first
    useEffect(() => {
      if (properties) {
        setPassedProperties({
          label: properties.label,
          name: properties.name,
          title: properties.title,
          fullName: properties.fullName,
          avatarUrl: properties.avatarUrl,
          photoUrl: properties.photoUrl,
          photos: properties.photos,
          description: properties.description,
        })
      }
    }, [properties])

    // First check the search index for this object
    useEffect(() => {
      if (!workspaceId || !objectType || !objectId) {
        setLocalMetadata(null) // Clear if IDs are missing
        return
      }

      // Reset state before attempting fetch
      setLocalMetadata(null)

      if (USE_SEARCH_INDEX_FIRST) {
        logger.dev('ObjectTile: Attempting fetch from searchIndex', {
          objectId,
          objectType,
        })
        const fetchFromSearchIndex = async () => {
          try {
            const searchEntry = await getDayObjectSearchEntry({
              workspaceId,
              objectType: objectType as NativeObjectType,
              objectId,
            })

            if (searchEntry) {
              logger.dev('ObjectTile: Found object in searchIndex', {
                objectId,
                objectType,
                label: searchEntry.label,
                photoUrl: searchEntry.photoUrl,
                descriptionFound: (searchEntry.object as any)?.description,
              })
              setLocalMetadata({
                label: searchEntry.label || null,
                // Use photoUrl from searchIndex for avatarUrl in local state
                avatarUrl: searchEntry.photoUrl || null,
                // Assuming description isn't stored directly on searchEntry root
                description: (searchEntry.object as any)?.description || null,
              })
            } else {
              logger.dev('ObjectTile: Not found in searchIndex', {
                objectId,
                objectType,
              })
              // Set to null if not found (server fetch might still occur)
              setLocalMetadata(null)
            }
          } catch (error) {
            logger.error('ObjectTile: Error fetching from searchIndex', error, {
              objectType,
              objectId,
              workspaceId,
            })
            setLocalMetadata(null) // Clear on error
          }
        }
        fetchFromSearchIndex()
      } else {
        // Flag is off, ensure local metadata is clear
        logger.dev('ObjectTile: Skipping searchIndex fetch due to flag.')
        setLocalMetadata(null)
      }
      // Removed the fallback to getObjectMetadata from indexedDb
    }, [workspaceId, objectType, objectId])

    // Determine if we need server data
    useEffect(() => {
      // Check if we have sufficient data from local sources
      const hasLabel =
        getFirstDefinedValue(passedProperties, LABEL_PROPERTIES) ||
        getFirstDefinedValue(localMetadata, LABEL_PROPERTIES)

      const hasAvatar =
        getFirstDefinedValue(passedProperties, AVATAR_PROPERTIES) ||
        getFirstDefinedValue(localMetadata, AVATAR_PROPERTIES)

      const hasDescription =
        getFirstDefinedValue(passedProperties, DESCRIPTION_PROPERTIES) ||
        getFirstDefinedValue(localMetadata, DESCRIPTION_PROPERTIES)

      // Only query if we're missing any required data
      setShouldQueryServer(!hasLabel || !hasAvatar || !hasDescription)
    }, [passedProperties, localMetadata])

    // Query for server metadata only when needed
    const { data } = useQuery(GET_TILE_METADATA, {
      variables: {
        objectId,
        objectType,
        workspaceId,
      },
      skip: !objectId || !objectType || !workspaceId || !shouldQueryServer,
      fetchPolicy: 'cache-first', // Prefer Apollo cache
    })

    // Effect to set server metadata from query
    useEffect(() => {
      if (data?.chipMetadata) {
        setServerMetadata({
          label: data.chipMetadata.label,
          avatarUrl: data.chipMetadata.avatarUrl,
          description: data.chipMetadata.description,
        })

        // Update the search index with the server data
        if (objectType && objectId && workspaceId) {
          // Create properties object
          const properties: Record<string, any> = { workspaceId }

          // Add specific properties from chipMetadata
          if (data.chipMetadata.label) {
            properties.label = data.chipMetadata.label
          }
          if (data.chipMetadata.avatarUrl) {
            properties.avatarUrl = data.chipMetadata.avatarUrl
          }
          if (data.chipMetadata.description) {
            properties.description = data.chipMetadata.description
          }
          if (data.chipMetadata.domain) {
            properties.domain = data.chipMetadata.domain
          }
          if (data.chipMetadata.icon) {
            properties.icon = data.chipMetadata.icon
          }

          updateObjectInSearchIndex(objectType as NativeObjectType, objectId, {
            label: data.chipMetadata.label,
            photoUrl: data.chipMetadata.avatarUrl,
            avatarUrl: data.chipMetadata.avatarUrl,
            description: data.chipMetadata.description,
            properties,
          }).catch((error) => {
            logger.error(
              'Failed to update search index with server data for tile',
              error
            )
          })
        }
      }
    }, [data, objectType, objectId, workspaceId])

    // Get best label based on priority
    const bestLabel = useMemo(() => {
      return getBestLabel({
        passedProperties,
        localMetadata,
        serverMetadata,
        objectType: objectType as NativeObjectType,
        objectId,
      })
    }, [passedProperties, localMetadata, serverMetadata, objectType, objectId])

    // Get best avatar URL based on priority
    const bestAvatarUrl = useMemo(() => {
      return (
        getFirstDefinedValue(passedProperties, AVATAR_PROPERTIES) ||
        getFirstDefinedValue(localMetadata, AVATAR_PROPERTIES) ||
        getFirstDefinedValue(serverMetadata, AVATAR_PROPERTIES) ||
        null
      )
    }, [passedProperties, localMetadata, serverMetadata])

    // Get best description based on priority
    const bestDescription = useMemo(() => {
      const fallbackDescription = objectId
      return (
        localMetadata?.description ||
        getFirstDefinedValue(passedProperties, DESCRIPTION_PROPERTIES) ||
        getFirstDefinedValue(localMetadata, DESCRIPTION_PROPERTIES) ||
        getFirstDefinedValue(serverMetadata, DESCRIPTION_PROPERTIES) ||
        fallbackDescription
      )
    }, [passedProperties, localMetadata, serverMetadata, objectId])

    const fallbackAvatar = useMemo(() => {
      if (objectType === NativeObjectTypes.Organization) {
        return (
          <DomainAvatar
            domain={objectId}
            size={size}
          />
        )
      } else if (objectType === NativeObjectTypes.Person) {
        return (
          <ContactAvatar
            email={objectId}
            size={size}
          />
        )
      } else if (
        objectType === NativeObjectTypes.Page &&
        (properties as Page)?.templateType
      ) {
        return (
          <TemplateIcon
            templateType={(properties as Page)?.templateType}
            size={size}
            variant="tile"
          />
        )
      } else if (
        objectType === NativeObjectTypes.Page &&
        (properties as Page)?.sourceTemplate?.templateType
      ) {
        return (
          <TemplateIcon
            templateType={(properties as Page)?.sourceTemplate?.templateType}
            size={size}
            variant="tile"
          />
        )
      } else {
        const objectIcon =
          ObjectTypeMetadata[objectType]?.icon || IconCircleFilled
        return (
          <Avatar
            src={null}
            sx={{
              width: size,
              height: size,
              background: (theme) => theme.palette.background.default,
            }}
          >
            {React.createElement(objectIcon, {
              size: size * 0.5,
              stroke: 2,
              style: {
                color: 'rgba(0, 0, 0, 0.54)',
              },
            })}
          </Avatar>
        )
      }
    }, [objectType, objectId, size, properties])

    return (
      <Box
        className="day-ai-object-tile"
        onClick={onClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          cursor: onClick ? 'pointer' : 'default',
        }}
      >
        {bestAvatarUrl ? (
          <Avatar
            src={bestAvatarUrl}
            sx={{ width: size, height: size }}
          />
        ) : (
          fallbackAvatar
        )}
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '18px',
              letterSpacing: '-0.28px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflowY: 'visible',
            }}
          >
            {bestLabel}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
              fontSize: '12px',
              letterSpacing: '-0.24px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              opacity: 0.8,
              lineHeight: '150%',
              height: '20px',
            }}
          >
            {bestDescription || ''}
          </Typography>
        </Box>
      </Box>
    )
  }
)

const CachedObjectTile = (props: ObjectTileProps) => {
  const { getCachedObject, setCachedObject } = useObjectCache()

  try {
    const cacheKey = getCacheKey({
      objectId: props.objectId,
      objectType: props.objectType,
      workspaceId: props.workspaceId,
      size: props.size,
    })

    const cachedObject = getCachedObject(cacheKey)
    if (cachedObject) {
      return cachedObject
    }

    const newObject = <BaseObjectTile {...props} />
    setCachedObject(cacheKey, newObject)
    return newObject
  } catch (error) {
    // Log error but render uncached version as fallback
    logger.error('Error in ObjectTile cache operations:', error, {
      objectType: props.objectType,
      objectId: props.objectId,
    })
    return <BaseObjectTile {...props} />
  }
}

export default CachedObjectTile
